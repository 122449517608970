import React from "react";
import { FiVolume2, FiVolumeX } from "react-icons/fi";

const SoundAndVibrationControls = ({ isMuted, setIsMuted }) => {
  const toggleMute = () => {
    setIsMuted((prev) => !prev); // Comută între mute/unmute
  };

  return (
    <div className="controls-container">
      <button
        className="control-button"
        onClick={toggleMute}
        aria-label="Mute/Unmute"
      >
        {isMuted ? <FiVolumeX size={24} /> : <FiVolume2 size={24} />}
      </button>
    </div>
  );
};

export default SoundAndVibrationControls;
