// src/context/UserContext.js
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import { SGXContext } from "./SGXContext";
import pb from "../pocketbase";

export const UserContext = createContext();
export const useUserContext = () => useContext(UserContext);

const totalsRecordId = "l9be51emzt683st"; 

const SYNCED_FIELDS = [
  'sgxBalance',
  'starBalance',
  'tapEarnRate',
  'energyRegenRate',
  'containers',
  'energy',
  'rankIndex',
  'xp',
  'invitedUsers',
  'rocketTicket',
  'bonusStarBalance'
];



const updateReferralStarBalance = async (inviterId, amount) => {
  try {
    const inviterRecord = await pb.collection("waitings").getFirstListItem(`userId="${inviterId}"`);
    const newReferralBalance = inviterRecord.referralStarBalance + amount;
    await pb.collection("waitings").update(inviterRecord.id, { referralStarBalance: newReferralBalance });
  } catch (error) {
  }
};

const ranks = [
  { name: "Rank 1", requiredTaps: 3000, image: "./rank1.png", energyLimit: 500 },
  { name: "Rank 2", requiredTaps: 10000, image: "./rank2.png", energyLimit: 700 },
  { name: "Rank 3", requiredTaps: 30000, image: "./rank3.png", energyLimit: 900 },
  { name: "Rank 4", requiredTaps: 50000, image: "./rank4.png", energyLimit: 1100 },
  { name: "Rank 5", requiredTaps: 70000, image: "./rank5.png", energyLimit: 1300 },
  { name: "Rank 6", requiredTaps: 90000, image: "./rank6.png", energyLimit: 1500 },
  { name: "Rank 7", requiredTaps: 150000, image: "./rank7.png", energyLimit: 1700 },
  { name: "Rank 8", requiredTaps: 200000, image: "./rank8.png", energyLimit: 1800 },
  { name: "Rank 9", requiredTaps: 250000, image: "./rank9.png", energyLimit: 1900 },
  { name: "Rank 10", requiredTaps: 500000, image: "./rank10.png", energyLimit: 2000 },
];

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const savedUser = JSON.parse(localStorage.getItem("user"));
    
    if (savedUser) {
      const lastLogoutTime = localStorage.getItem("lastLogoutTime");
      const currentTime = Date.now();
      if (lastLogoutTime) {
        const timePassedInSeconds = (currentTime - parseInt(lastLogoutTime)) / 1000;
        const energyToAdd = Math.floor(timePassedInSeconds / savedUser.energyRegenRate);
        const currentRank = ranks[savedUser.rankIndex];
        savedUser.energy = Math.min(
          savedUser.energy + energyToAdd, 
          currentRank.energyLimit
        );
      }
    }
    
    return savedUser || {
      username: "",
      email: "",
      sgxBalance: 0,
      starBalance: 0,
      tapEarnRate: 1,
      energyRegenRate: 2,
      containers: 0,
      energy: 0,
      rankIndex: 0,
      prevRankIndex: 0,
      xp: 0,
      invitedUsers: 0,
      rocketTicket: 0,
      waitingTasks: [],
      canCollectTasks: [],
      completedTasks: [],
      adCooldownTasks: [],
      usedCodes: [],
      referralStarBalance: 0,
      inviterId: null,
      bonusStarBalance: 0,
    };
  });

 

  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const savedAuth = localStorage.getItem("isAuthenticated") === "true";
    const savedUser = JSON.parse(localStorage.getItem("user"));
    return savedAuth && savedUser && savedUser.id;
  });

  const [isFetching, setIsFetching] = useState(true);
  const lastSyncTimeRef = useRef(Date.now());
  const syncTimeoutRef = useRef(null);

  const prepareSyncData = (userData) => {
  const syncData = {};
  SYNCED_FIELDS.forEach((field) => {
    if (userData.hasOwnProperty(field)) {
      syncData[field] = userData[field]; 
    }
  });
  return syncData;
};


  const checkAndSyncUserData = async (userId) => {
  try {
    const localData = localStorage.getItem("user");
    
   
    if (!localData || !JSON.parse(localData).starBalance) {
      const pocketBaseUser = await pb.collection("users").getOne(userId);
      
      
      if (pocketBaseUser.starBalance > 0 || pocketBaseUser.xp > 0 || pocketBaseUser.containers > 0) {

        const syncedUser = {
          ...user,
          ...pocketBaseUser,
          id: userId
        };
        
        localStorage.setItem("user", JSON.stringify(syncedUser));
        setUser(syncedUser);
      }
    }
  } catch (error) {
    console.error("Error checking and syncing user data:", error);
  }
};


  
const syncWithPocketBase = async () => {
  if (!user.id) return;

  try {
    
    updateEnergyOffline();

    
    await applyPendingUpdates(user.id);

    
    const localUserData = JSON.parse(localStorage.getItem("user"));
    if (!localUserData) {
      console.error("LocalStorage user data missing. Sync skipped.");
      return;
    }

    const { bonusStarBalance, inviterId } = localUserData;

    
    if (bonusStarBalance > 0 && inviterId) {
      await updateReferralStarBalance(inviterId, bonusStarBalance);
      

      
      localUserData.bonusStarBalance = 0;
    }

    
    const syncData = prepareSyncData(localUserData);

    
    const pocketBaseUser = await pb.collection("users").update(user.id, syncData);

    
    localStorage.setItem("user", JSON.stringify(localUserData));
    setUser((prevUser) => ({ ...prevUser, ...localUserData }));

    lastSyncTimeRef.current = Date.now();
  } catch (error) {
    console.error("Error syncing with PocketBase:", error);
  }
};

const applyPendingUpdates = async (userId) => {
  try {
    
    const waitingRecord = await pb.collection("waitings").getFirstListItem(`userId="${userId}"`);

    if (
      !waitingRecord ||
      (!waitingRecord.waitingContainersUpdate && !waitingRecord.waitingInvitedUsersUpdate)
    ) {
      return;
    }

   
    const containersUpdate = waitingRecord.waitingContainersUpdate || 0;
    const invitedUsersUpdate = waitingRecord.waitingInvitedUsersUpdate || 0;

    
    setUser((prevUser) => {
      const updatedUser = {
        ...prevUser,
        containers: prevUser.containers + containersUpdate,
        invitedUsers: prevUser.invitedUsers + invitedUsersUpdate,
      };

      
      localStorage.setItem("user", JSON.stringify(updatedUser));
      return updatedUser;
    });

    
    await pb.collection("waitings").update(waitingRecord.id, {
      waitingContainersUpdate: 0,
      waitingInvitedUsersUpdate: 0,
    });

    
  } catch (error) {
    console.error("Error applying pending updates:", error);
  }
};




  const scheduleNextSync = () => {
  if (syncTimeoutRef.current) {
    clearTimeout(syncTimeoutRef.current);
  }

  const lastSync = parseInt(localStorage.getItem("lastSyncTime")) || 0;
  const currentTime = Date.now();
  const timeSinceLastSync = currentTime - lastSync;

  const syncInterval = 2 * 60 * 1000; 
  const timeUntilNextSync = Math.max(syncInterval - timeSinceLastSync, 0);


  syncTimeoutRef.current = setTimeout(async () => {
    await syncWithPocketBase();
    localStorage.setItem("lastSyncTime", Date.now().toString());
    scheduleNextSync(); 
  }, timeUntilNextSync);
};

  
  useEffect(() => {
  const initializeSync = async () => {
    
    updateEnergyOffline();

    const lastSync = parseInt(localStorage.getItem("lastSyncTime")) || 0;
    const currentTime = Date.now();
    const syncInterval = 2 * 60 * 1000; 

    
    if (currentTime - lastSync >= syncInterval) {
      await syncWithPocketBase();
      localStorage.setItem("lastSyncTime", currentTime.toString());
    }

    scheduleNextSync(); 
  };

  initializeSync();

  return () => {
    if (syncTimeoutRef.current) {
      clearTimeout(syncTimeoutRef.current);
    }
  };
}, [isAuthenticated, user.id]);



  const startSyncInterval = () => {
    const interval = setInterval(() => {
      const lastSync = parseInt(localStorage.getItem("lastSync")) || 0;
      const currentTime = Date.now();
      if (currentTime - lastSync >= 2 * 60 * 1000) { 
        syncWithPocketBase();
        localStorage.setItem("lastSync", currentTime.toString());
      }
    }, 60 * 1000); 
    return () => clearInterval(interval);
  };

  useEffect(startSyncInterval, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem("lastLogoutTime", Date.now().toString());
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  useEffect(() => {
  const currentUser = pb.authStore.model;
  if (currentUser && isFetching) {
    fetchUserData(currentUser.id);
  }
}, [pb.authStore.model, isFetching]);


const updateTasks = async (newCompletedTasks, newCanCollectTasks, newWaitingTasks, newAdCooldownTasks) => {
  try {
    const userRecord = await pb.collection('users').getOne(user.id);

    const updatedUserData = {
      completedTasks: newCompletedTasks || userRecord.completedTasks || [],
      canCollectTasks: newCanCollectTasks || userRecord.canCollectTasks || [],
      waitingTasks: newWaitingTasks || userRecord.waitingTasks || [],
      adCooldownTasks: newAdCooldownTasks || userRecord.adCooldownTasks || [],
    };

    await pb.collection('users').update(user.id, updatedUserData);

    setUser((prevUser) => ({
      ...prevUser,
      ...updatedUserData,
    }));

  } catch (error) {
    console.error('Error updating tasks:', error);
  }
};


const addUsedCode = async (code) => {
  try {
    const updatedUsedCodes = user.usedCodes ? [...user.usedCodes, code] : [code];

    
    await pb.collection('users').update(user.id, { usedCodes: updatedUsedCodes });

    
    setUser((prevUser) => ({
      ...prevUser,
      usedCodes: updatedUsedCodes,
    }));

  } catch (error) {
    console.error('Error updating used codes:', error);
  }
};



  

  useEffect(() => {
    if (!isFetching && user.id) {
      localStorage.setItem("user", JSON.stringify(user));
    }
  }, [user, isFetching]);


  const updateUser = (updates) => {
    setUser((prevUser) => {
      const newUser = { ...prevUser, ...updates };
      localStorage.setItem("user", JSON.stringify(newUser));
      return newUser;
    });
  };

  const resetUser = () => {
    setUser({
      username: "",
      email: "",
      sgxBalance: 0,
      starBalance: 0,
      tapEarnRate: 1,
      energyRegenRate: 2,
      containers: 0,
      energy: 0,
      rankIndex: 0,
      xp: 0,
      invitedUsers: 0,
      rocketTicket: 0,
      bonusStarBalance: 0,
    });
    setIsFetching(true);
    setIsAuthenticated(false);
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("user");
  };

  const loginUser = async () => {
  try {
    const currentUser = pb.authStore.model; 
    if (currentUser) {
      
      await fetchUserData(currentUser.id, true); 
    }
    setIsAuthenticated(true);
    localStorage.setItem("isAuthenticated", "true");
  } catch (error) {
    console.error("Error during login process:", error);
  }
};

  
  const regenerateEnergy = () => {
  setUser((prevUser) => {
    const currentRank = ranks[prevUser.rankIndex];
    const energyLimit = currentRank.energyLimit;
    if (prevUser.energy < energyLimit) {
      const updatedUser = {
        ...prevUser,
        energy: Math.min(prevUser.energy + 1, energyLimit),
      };
      localStorage.setItem("user", JSON.stringify(updatedUser));
      localStorage.setItem("lastEnergyUpdate", Date.now().toString());
      return updatedUser;
    }
    return prevUser;
  });
};



  const handleTap = () => {
    setUser((prevUser) => {
      if (prevUser.energy > 0) {
        const newBalance = prevUser.starBalance + prevUser.tapEarnRate;
        const newXP = prevUser.xp + 1;
        const referralBonus = prevUser.tapEarnRate * 0.05;
        let newBonusStarBalance = prevUser.bonusStarBalance + referralBonus;

        let newRankIndex = prevUser.rankIndex;
        let newContainers = prevUser.containers;

        if (newXP >= ranks[prevUser.rankIndex].requiredTaps) {
          if (prevUser.rankIndex < ranks.length - 1) {
            newRankIndex = Math.min(prevUser.rankIndex + 1, ranks.length - 1);
            newContainers += 1;
          }
        }

        const updatedUser = {
          ...prevUser,
          starBalance: newBalance,
          xp: newRankIndex > prevUser.rankIndex ? 0 : newXP,
          rankIndex: newRankIndex,
          prevRankIndex: prevUser.rankIndex,
          containers: newContainers,
          energy: prevUser.energy - 1,
          bonusStarBalance: newBonusStarBalance,
        };

        localStorage.setItem("user", JSON.stringify(updatedUser));
        return updatedUser;
      }
      return prevUser;
    });
  };
  
  
  const fetchUserData = async (userId, forceSync = false) => {
  try {
    
    const userRecord = await pb.collection("users").getOne(userId);

    
    const savedUser = JSON.parse(localStorage.getItem("user")) || {};

    let mergedUser;

    if (forceSync) {
      
      mergedUser = {
        ...userRecord, 
        id: userId, 
      };
    } else {
      
      mergedUser = {
        ...savedUser,
        username: userRecord.username || savedUser.username || "", 
        id: userId, 
      };
    }

    localStorage.setItem("user", JSON.stringify(mergedUser));
    setUser(mergedUser);

    setIsAuthenticated(true);
    setIsFetching(false);

  } catch (error) {
    console.error("Error fetching user data:", error);
    setIsFetching(false);
  }
};

  
  const updateStarBalance = (amount) => {
    setUser((prevUser) => {
      const newStarBalance = prevUser.starBalance + amount;
      const referralBonus = amount * 0.05;
      const newBonusStarBalance = (prevUser.bonusStarBalance || 0) + referralBonus; 

      const updatedUser = {
        ...prevUser,
        starBalance: newStarBalance,
        bonusStarBalance: newBonusStarBalance,
      };

      localStorage.setItem("user", JSON.stringify(updatedUser));
      return updatedUser;
    });
  };

  const updateRocketTicket = (amount) => {
    setUser((prevUser) => ({
      ...prevUser,
      rocketTicket: prevUser.rocketTicket + amount,
    }));
  };

  const updateSGXBalance = (amount) => {
    setUser((prevUser) => {
      const newSgxBalance = prevUser.sgxBalance + amount;
      const updatedUser = {
        ...prevUser,
        sgxBalance: newSgxBalance,
      };
      localStorage.setItem("user", JSON.stringify(updatedUser));
      return updatedUser;
    });
  };

  const updateEnergyOffline = () => {
  const savedUser = JSON.parse(localStorage.getItem("user"));
  if (!savedUser) return; 

  const lastUpdate = parseInt(localStorage.getItem("lastEnergyUpdate")) || Date.now();
  const currentTime = Date.now();
  const timeElapsed = (currentTime - lastUpdate) / 1000; 

  const { energyRegenRate, rankIndex, energy } = savedUser;
  const currentRank = ranks[rankIndex];
  const energyLimit = currentRank.energyLimit;

  
  const energyToAdd = Math.floor(timeElapsed / energyRegenRate);

  
  const updatedEnergy = Math.min(energy + energyToAdd, energyLimit);

  if (energyToAdd > 0) {
    const updatedUser = {
      ...savedUser,
      energy: updatedEnergy, 
    };

   
    localStorage.setItem("user", JSON.stringify(updatedUser));
    localStorage.setItem("lastEnergyUpdate", currentTime.toString()); 
    setUser(updatedUser); 
  }
};

useEffect(() => {
  updateEnergyOffline(); 
}, []);


  const updateEnergyRegenRate = (amount) => {
    setUser((prevUser) => ({
      ...prevUser,
      energyRegenRate: Math.max(0.1, prevUser.energyRegenRate - amount),
    }));
  };

  const updateTapEarnRate = (amount) => {
    setUser((prevUser) => ({
      ...prevUser,
      tapEarnRate: Math.min(10, prevUser.tapEarnRate + amount),
    }));
  };

  const decrementContainerCount = () => {
    setUser((prevUser) => ({
      ...prevUser,
      containers: Math.max(prevUser.containers - 1, 0),
    }));
  };

  const updateContainers = (count) => {
    setUser((prevUser) => ({
      ...prevUser,
      containers: prevUser.containers + count,
    }));
  };

  const updateInvitedUsers = (count) => {
    setUser((prevUser) => ({
      ...prevUser,
      invitedUsers: prevUser.invitedUsers + count,
    }));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      regenerateEnergy();
    }, user.energyRegenRate * 1000);
    return () => clearInterval(interval);
  }, [user.energyRegenRate]);

  return (
    <UserContext.Provider
      value={{
        user,
        isAuthenticated,
        loginUser,
        handleTap,
        regenerateEnergy,
        updateStarBalance,
        updateRocketTicket,
        updateSGXBalance,
        updateEnergyRegenRate,
        updateTapEarnRate,
        decrementContainerCount,
        updateInvitedUsers,
        updateUser,
        updateContainers,
        resetUser,
        syncWithPocketBase,
        updateTasks,
        addUsedCode,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
