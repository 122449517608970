import React, { useState, useContext, useRef } from "react";
import { UserContext } from "../context/UserContext";
import { RiCoinFill } from "react-icons/ri";
import { FaInfoCircle, FaCheckCircle, FaTicketAlt } from "react-icons/fa";
import "./App.css";

const MAX_BET = 10000;

const Bet = () => {
  const { user, updateUser, updateStarBalance } = useContext(UserContext);
  const [currentXValue, setCurrentXValue] = useState(0.0);
  const [betAmount, setBetAmount] = useState(1000);
  const [isGameActive, setIsGameActive] = useState(false);
  const [profit, setProfit] = useState(0);
  const [fixedProfit, setFixedProfit] = useState(0);
  const [fixedXValue, setFixedXValue] = useState(0);
  const [isWithdrawn, setIsWithdrawn] = useState(false);
  const [gameInterval, setGameInterval] = useState(null);
  const [isRocketShaking, setIsRocketShaking] = useState(false);
  const [isRocketIgnited, setIsRocketIgnited] = useState(false);
  const [backgroundPositionY, setBackgroundPositionY] = useState("100%");
  const [countdown, setCountdown] = useState(3);
  const [showCountdownModal, setShowCountdownModal] = useState(false);
  const [hasLaunched, setHasLaunched] = useState(false);
  const [isRocketFalling, setIsRocketFalling] = useState(false);
  const [showEndGameModal, setShowEndGameModal] = useState(false);
  const [showWithdrawMessage, setShowWithdrawMessage] = useState(false);
  const [showNoTicketsMessage, setShowNoTicketsMessage] = useState(false);
  const [showNoBalanceMessage, setShowNoBalanceMessage] = useState(false);
  const [showMaxBetMessage, setShowMaxBetMessage] = useState(false);
  const [isGameFinished, setIsGameFinished] = useState(false);
  const [isRocketVisible, setIsRocketVisible] = useState(true);

  const countdownAudio = useRef(null);
  const propulsionAudio = useRef(null);
  const crashAudio = useRef(null);

  const backgroundSpeedFactor = 1.0;

  const generateExplosionValue = () => {
    const maxExplosion = 10;
    const minExplosion = 2;

    const randomFactor = Math.pow(Math.random(), 1);
    return minExplosion + randomFactor * (maxExplosion - minExplosion);
  };

  const startGame = () => {
    if (user.rocketTicket < 1) {
      showTemporaryMessage(setShowNoTicketsMessage);
      return;
    }

    if (betAmount > MAX_BET) {
      showTemporaryMessage(setShowMaxBetMessage);
      return;
    }

    if (user.starBalance < betAmount) {
      showTemporaryMessage(setShowNoBalanceMessage);
      return;
    }

    updateUser({
      rocketTicket: user.rocketTicket - 1,
      starBalance: user.starBalance - betAmount,
    });

    // Setăm volumul pentru fiecare sunet la începutul jocului
    countdownAudio.current.volume = 0.1;
    propulsionAudio.current.volume = 0.1;
    crashAudio.current.volume = 0.1;

    countdownAudio.current.play();

    countdownAudio.current.play();

    setShowCountdownModal(true);

    let countdownValue = 3;
    setCountdown(countdownValue);

    const countdownInterval = setInterval(() => {
      countdownValue -= 1;
      setCountdown(countdownValue);

      if (countdownValue <= 0) {
        clearInterval(countdownInterval);
        setShowCountdownModal(false);
        startRocketAnimation();
      }
    }, 1000);
  };

  const startRocketAnimation = () => {
    setIsGameActive(true);
    setIsWithdrawn(false);
    setProfit(0);
    setFixedProfit(0);
    setFixedXValue(0);
    setCurrentXValue(0);
    setBackgroundPositionY("100%");
    setHasLaunched(false);
    setIsRocketFalling(false);

    const explosionValue = generateExplosionValue();
    let xValue = 0.0;
    let increment = 0.003;

    setIsRocketShaking(true);
    setIsRocketIgnited(true);

    propulsionAudio.current.play();
    propulsionAudio.current.loop = true;

    setTimeout(() => {
      setIsRocketShaking(false);
      setHasLaunched(true);

      const interval = setInterval(() => {
        xValue += increment;

        if (xValue >= explosionValue) {
          clearInterval(interval);
          initiateRocketFall();
        } else {
          setCurrentXValue(parseFloat(xValue.toFixed(2)));
          const adjustedBackgroundPosition = `${100 - (xValue / 10) * 100}%`;
          setBackgroundPositionY(adjustedBackgroundPosition);

          if (!isWithdrawn) {
            setProfit(betAmount * (xValue + 1));
          }

          increment += xValue / 5000;
        }
      }, 100);

      setGameInterval(interval);
    }, 1000);
  };

  const initiateRocketFall = () => {
    setIsRocketFalling(true);
    setIsGameActive(false);
    setIsGameFinished(true);

    propulsionAudio.current.pause();
    crashAudio.current.play();

    setTimeout(() => {
      setIsRocketFalling(false);
      setIsRocketVisible(false);
      setShowEndGameModal(true);
    }, 2000);
  };

  const resetGame = () => {
    setCurrentXValue(0.0);
    setBetAmount(1000);
    setIsGameActive(false);
    setProfit(0);
    setFixedProfit(0);
    setFixedXValue(0);
    setIsWithdrawn(false);
    setIsRocketShaking(false);
    setIsRocketIgnited(false);
    setBackgroundPositionY("100%");
    setCountdown(3);
    setShowCountdownModal(false);
    setHasLaunched(false);
    setIsRocketFalling(false);
    setShowEndGameModal(false);
    setShowWithdrawMessage(false);
    clearInterval(gameInterval);
    setIsGameFinished(false);

    setIsRocketVisible(true);
  };

  const showTemporaryMessage = (setMessageFunction) => {
    setMessageFunction(true);
    setTimeout(() => {
      setMessageFunction(false);
    }, 5000);
  };

  const showTemporaryWithdrawMessage = () => {
    setShowWithdrawMessage(true);
    setTimeout(() => {
      setShowWithdrawMessage(false);
    }, 10000);
  };

  const withdrawProfit = () => {
    if (!isGameActive || isWithdrawn) return;

    const currentProfit = profit;
    setFixedProfit(currentProfit);
    setFixedXValue(currentXValue);
    setIsWithdrawn(true);
    showTemporaryWithdrawMessage();

    updateStarBalance(currentProfit);

    document.getElementById("betButton").classList.add("waiting-button");
  };

  const decreaseBetAmount = () => {
    setBetAmount((prevAmount) => Math.max(prevAmount - 1000, 1000));
  };

  const increaseBetAmount = () => {
    setBetAmount((prevAmount) => prevAmount + 1000);
  };

  const validateAndCorrectBetAmount = (value) => {
    if (value.startsWith("0")) {
      return parseInt(value, 10);
    }
    return value;
  };

  const handleBetAmountChange = (event) => {
    let value = event.target.value;

    if (value === "") {
      setBetAmount("");
      return;
    }

    let parsedValue = validateAndCorrectBetAmount(value);

    if (!isNaN(parsedValue) && parsedValue >= 0) {
      if (parsedValue > MAX_BET) {
        showTemporaryMessage(setShowMaxBetMessage);
      } else {
        setBetAmount(parsedValue);
      }
    }
  };

  const handleBetAmountBlur = () => {
    if (betAmount === "" || betAmount < 1000) {
      setBetAmount(1000);
    }
  };

  const handleButtonClick = () => {
    if (isGameActive && !isWithdrawn) {
      withdrawProfit();
    } else if (!isGameActive) {
      startGame();
    }
  };

  return (
    <section className="game-display-2">
      {showNoTicketsMessage && (
        <div
          className={`promo-message ${showNoTicketsMessage ? "show" : "hide"}`}
          style={{
            color: "white",
            fontWeight: "500",
            background:
              "#31313198",
          }}
        >
          <FaInfoCircle style={{ verticalAlign: "middle" }} />
          Not enough rocket tickets!
        </div>
      )}

      {showNoBalanceMessage && (
        <div
          className={`promo-message ${showNoBalanceMessage ? "show" : "hide"}`}
          style={{
            color: "white",
            fontWeight: "500",
            background:
              "#31313198",
          }}
        >
          <FaInfoCircle style={{ verticalAlign: "middle" }} />
          Not enough balance to place bet!
        </div>
      )}

      {showMaxBetMessage && (
        <div
          className={`promo-message ${showMaxBetMessage ? "show" : "hide"}`}
          style={{
            color: "white",
            fontWeight: "500",
            background:
              "#31313198",
          }}
        >
          <FaInfoCircle style={{ verticalAlign: "middle" }} />
          The maximum bet amount is 10,000 $STAR!
        </div>
      )}

      {showWithdrawMessage && (
        <div
          className={`promo-message ${showWithdrawMessage ? "show" : "hide"}`}
          style={{
            color: "white",
            fontWeight: "500",
            background:
              "#31313198",
          }}
        >
          <FaCheckCircle style={{ verticalAlign: "middle" }} />
          Profit Withdrawn: {fixedProfit.toFixed(2)} $STAR at x
          {(fixedXValue + 1).toFixed(2)}
        </div>
      )}

      <div className="rocket-game-stats">
        <div className="stat-box" style={{ justifyContent: "end" }}>
          <p className="stat-label">
            <RiCoinFill />
            Balance:
          </p>
          <p className="stat-value">
            {user.starBalance.toLocaleString()} $STAR
          </p>
        </div>
        <div className="stat-box">
          <p className="stat-label">
            <FaTicketAlt />
            Tickets:
          </p>
          <p className="stat-value">{user.rocketTicket}</p>
        </div>
      </div>
      <div
        className={`rocket-display ${
          isGameActive ? "rocket-background-scroll" : ""
        } ${!isGameActive && isRocketIgnited ? "rocket-background-fall" : ""}`}
        style={{ backgroundPositionY }}
      >
        <audio ref={countdownAudio} src="countdown.mp3" preload="auto"></audio>
        <audio
          ref={propulsionAudio}
          src="propulsion.mp3"
          preload="auto"
        ></audio>
        <audio ref={crashAudio} src="crash.mp3" preload="auto"></audio>

        {showCountdownModal && (
          <div className="game-countdown-modal">
            <h2>The round starts in:</h2>
            <div className="countdown-number">{countdown}</div>
          </div>
        )}

        {showEndGameModal && (
          <div className="rankup-overlay">
            <div className="game-end-modal">
              <h2>The rocket has crashed!</h2>
              <p>Do you have more tickets? Let's play another round!</p>
              <button onClick={resetGame}>Back to game</button>
            </div>
          </div>
        )}

        <div className="game-objects">
          <div className="rocket-container">
            <div className="progress-bar-container">
              <div className="progress-bar">
                <div
                  className="progress-bar-fill"
                  style={{ height: `${(currentXValue / 10) * 100}%` }}
                />
                <img
                  src="rocket.webp"
                  alt="Mini Rocket"
                  className="mini-rocket"
                  style={{ bottom: `${(currentXValue / 10) * 100}%` }}
                />
              </div>
            </div>

            {isRocketVisible && (
              <div className="rocket-containers">
                <img
                  src="/rocket.webp"
                  alt="Rocket"
                  className={`rocket ${
                    isRocketShaking
                      ? "rocket-shake"
                      : hasLaunched
                      ? "rocket-float"
                      : ""
                  } ${isRocketFalling ? "rocket-fall" : ""}`}
                />
                {isRocketIgnited && (
                  <>
                    <img
                      src="propulsion.gif"
                      alt="Rocket Propulsion"
                      className={`rocket-thrust ${
                        isRocketFalling ? "hidden" : ""
                      } ${isRocketShaking ? "rocket-shake" : ""} ${
                        hasLaunched ? "rocket-float" : ""
                      }`}
                    />
                    <div className="rocket-smoke"></div>
                  </>
                )}
              </div>
            )}

            <div className="current-x-value">
              x{(currentXValue + 1).toFixed(2)}
            </div>
          </div>
        </div>
      </div>
      <div className="game-controls">
        <div className="bet-amount">
          <input
            type="number"
            value={betAmount}
            onChange={handleBetAmountChange}
            onBlur={handleBetAmountBlur}
            min="1000"
            className="bet-input"
          />
        </div>
      </div>
      <button
        id="betButton"
        className={`start-bet ${
          isWithdrawn || isGameFinished ? "waiting-button" : ""
        }`}
        onClick={handleButtonClick}
        style={{
          backgroundImage:
            isGameActive && !isWithdrawn
              ? "linear-gradient(to right, orange 70%, rgba(255, 165, 0, 0.5) 100%)"
              : "radial-gradient(circle at 0% 0.5%, rgb(241, 241, 242) 0.1%, rgb(224, 226, 228) 100.2%)",
          backgroundColor: isWithdrawn || isGameFinished ? "#ccc" : "",
          color: isGameActive && !isWithdrawn ? "#ffffff" : "#0a0a0a",
          pointerEvents: isWithdrawn || isGameFinished ? "none" : "auto",
        }}
      >
        {isWithdrawn || isGameFinished
          ? "WAITING"
          : isGameActive
          ? `WITHDRAWAL ${profit.toFixed(2)} $STAR`
          : `START THE ROUND `}
      </button>
    </section>
  );
};

export default Bet;
