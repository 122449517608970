import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { FaTwitter, FaInstagram, FaUserPlus, FaCheckCircle, FaTelegramPlane, FaDollarSign } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import { FaPlay } from "react-icons/fa6";
import { IoReader } from "react-icons/io5";
import { MdInstallMobile } from "react-icons/md";
import pb from '../pocketbase';

pb.autoCancellation(false);
/* global show_8444483 */


const tasks = [
  { id: 1, name: "Follow us on X.com", description: "Get a Case as a reward.", action: "https://twitter.com/StarlessXCore", reward: { containers: 1 } },
  { id: 2, name: "Follow us on Instagram", description: "Get 1 Rocket Ticket as a reward.", action: "https://www.instagram.com/starlessxcore/", reward: { rocketTicket: 1 } },
  { id: 3, name: "Join the Telegram channel", description: "Get 25.000 $STAR as a reward.", action: "https://t.me/TheStarlessX", reward: { starBalance: 25000 } },
  { id: 4, name: "Watch a sponsored Ad", description: "Get a Case as a reward.", action: "", reward: { containers: 1 } },
  { id: 5, name: "Watch a sponsored Ad", description: "Get 1 Rocket Ticket as a reward.", action: "", reward: { rocketTicket: 1 } },
  { id: 6, name: "Learn about StarlessX", description: "Get 25.000 $STAR as a reward.", action: "https://starlessx.com", reward: { starBalance: 25000 } },
  { id: 7, name: "Invite 25 friends", description: "Receive 3 Cases as a reward.", target: 25, reward: { containers: 3 } },
  { id: 8, name: "Invite 100 friends", description: "Receive 10 Cases as a reward.", target: 100, reward: { containers: 10 } }
];

const TasksSection = () => {
  const { user, updateUser, updateTasks, updateStarBalance } = useContext(UserContext);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [canCollectTasks, setCanCollectTasks] = useState([]);
  const [waitingTasks, setWaitingTasks] = useState([]);
  const [adCooldownTasks, setAdCooldownTasks] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isLoadingTasks, setIsLoadingTasks] = useState(true);
  const [showAdModal, setShowAdModal] = useState(false);
  const [adCountdown, setAdCountdown] = useState(null);
  const [isTask4Loading, setIsTask4Loading] = useState(true);
  const [adCountdown5, setAdCountdown5] = useState(null); // Pentru task-ul 5
  const [showAdModalTask5, setShowAdModalTask5] = useState(false);


  // Fetch initial data including `adCooldownTasks`
  useEffect(() => {
    const fetchUserTasks = async () => {
      setIsLoadingTasks(true);
      try {
        const userRecord = await pb.collection('users').getOne(user.id);
        setCompletedTasks(userRecord.completedTasks || []);
        setCanCollectTasks(userRecord.canCollectTasks || []);
        setWaitingTasks(userRecord.waitingTasks || []);
        setAdCooldownTasks(userRecord.adCooldownTasks || []);

        // Set `adCountdown` if task 4 has a cooldown period
        const task4Cooldown = userRecord.adCooldownTasks?.find(({ taskId }) => taskId === 4);
        if (task4Cooldown && task4Cooldown.expireAt > Date.now()) {
          setAdCountdown(task4Cooldown.expireAt - Date.now());
        }
      } catch (fetchError) {
        console.error("Error fetching user tasks:", fetchError);
      } finally {
        setIsLoadingTasks(false);
        setTimeout(() => setIsTask4Loading(false), 3000); // 2 seconds load + 1 second delay for task 6
      }
    };

    if (user.id) {
      fetchUserTasks();
    }
  }, [user.id]);

  const checkWaitingTasks = async () => {
    const now = Date.now();
    const updatedCanCollectTasks = [...canCollectTasks];
    const updatedWaitingTasks = waitingTasks.filter(({ taskId, expireAt }) => {
      if (expireAt <= now) {
        if (!updatedCanCollectTasks.includes(taskId)) {
          updatedCanCollectTasks.push(taskId);
        }
        return false;
      }
      return true;
    });

    if (
      JSON.stringify(canCollectTasks) !== JSON.stringify(updatedCanCollectTasks) ||
      JSON.stringify(waitingTasks) !== JSON.stringify(updatedWaitingTasks)
    ) {
      setCanCollectTasks(updatedCanCollectTasks);
      setWaitingTasks(updatedWaitingTasks);
      await updateTasks(completedTasks, updatedCanCollectTasks, updatedWaitingTasks, adCooldownTasks);
    }
  };

  const checkAdCooldownTasks = async () => {
    const now = Date.now();
    const updatedAdCooldownTasks = adCooldownTasks.filter(({ taskId, expireAt }) => {
      if (expireAt > now) {
        if (taskId === 4) setAdCountdown(expireAt - now);
        if (taskId === 5) setAdCountdown5(expireAt - now);
        return true;
      }
      return false;
    });
  
    if (adCooldownTasks.length !== updatedAdCooldownTasks.length) {
      setAdCooldownTasks(updatedAdCooldownTasks);
      await updateTasks(completedTasks, canCollectTasks, waitingTasks, updatedAdCooldownTasks);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      checkWaitingTasks();
      checkAdCooldownTasks();
  
      if (adCountdown) {
        setAdCountdown((prevCountdown) => (prevCountdown > 1000 ? prevCountdown - 1000 : null));
      }
      if (adCountdown5) {
        setAdCountdown5((prevCountdown) => (prevCountdown > 1000 ? prevCountdown - 1000 : null));
      }
    }, 1000);
  
    return () => clearInterval(intervalId);
  }, [adCooldownTasks, adCountdown, adCountdown5]);

  const formatTime = (ms) => {
    const hours = String(Math.floor(ms / 3600000)).padStart(2, '0');
    const minutes = String(Math.floor((ms % 3600000) / 60000)).padStart(2, '0');
    const seconds = String(Math.floor((ms % 60000) / 1000)).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  const handleTaskStart = async (taskId) => {
    const task = tasks.find((task) => task.id === taskId);
    if (!task) return;
  
    if (taskId === 5) {
      show_8444483()
        .then(async () => {
          rewardUserForTask(taskId);
    
          const cooldown = 1 * 60 * 60 * 1000; // 1 oră pentru ID 5
          const expireAt = Date.now() + cooldown;
    
          const updatedAdCooldownTasks = [...adCooldownTasks, { taskId, expireAt }];
          setAdCooldownTasks(updatedAdCooldownTasks);
    
          setAdCountdown5(cooldown); // Countdown specific task-ului 5
          setShowAdModalTask5(true); // Activează modalul specific task-ului 5
    
          await updateTasks(completedTasks, canCollectTasks, waitingTasks, updatedAdCooldownTasks);
    
          setTimeout(() => setShowModal(true), 40000); // Poți ajusta acest timeout dacă e necesar
        })
        .catch((error) => {
          console.error("Ad display error:", error);
        });
    } else if (taskId === 4) {
      show_8444483()
        .then(async () => {
          rewardUserForTask(taskId);
    
          const cooldown = 3 * 60 * 60 * 1000; // 3 ore pentru ID 4
          const expireAt = Date.now() + cooldown;
    
          const updatedAdCooldownTasks = [...adCooldownTasks, { taskId, expireAt }];
          setAdCooldownTasks(updatedAdCooldownTasks);
    
          setAdCountdown(cooldown); // Countdown specific task-ului 4
          setShowAdModal(true); // Activează modalul specific task-ului 4
    
          await updateTasks(completedTasks, canCollectTasks, waitingTasks, updatedAdCooldownTasks);
    
          setTimeout(() => setShowModal(true), 40000);
        })
        .catch((error) => {
          console.error("Ad display error:", error);
        });
    }
     else {
      // Alte tipuri de task-uri
      const expireAt = Date.now() + 600000; // 10 minute pentru task-uri generale
      const updatedWaitingTasks = [...waitingTasks, { taskId, expireAt }];
      setWaitingTasks(updatedWaitingTasks);
  
      await updateTasks(completedTasks, canCollectTasks, updatedWaitingTasks, adCooldownTasks);
  
      if (task.action) {
        setTimeout(() => {
          window.location.assign(task.action);
        }, 30);
      }
  
      setTimeout(() => setShowModal(true), 5000);
    }
  };
  

// Funcție nouă pentru a recompensa utilizatorul
const rewardUserForTask = (taskId) => {
  const taskToComplete = tasks.find((task) => task.id === taskId);
  if (taskToComplete?.reward?.containers) {
    updateUser({ containers: user.containers + taskToComplete.reward.containers });
  } else if (taskToComplete?.reward?.rocketTicket) {
    updateUser({ rocketTicket: user.rocketTicket + taskToComplete.reward.rocketTicket });
  } else if (taskToComplete?.reward?.starBalance) {
    updateStarBalance(taskToComplete.reward.starBalance); // Folosim funcția din context
  }
};


const handleCloseModal = () => {
  setShowModal(false);
  setShowAdModal(false); // Închide modalul pentru task-ul 4
  setShowAdModalTask5(false); // Închide modalul pentru task-ul 5
};

  const handleTaskCompletion = (taskId) => {
    const updatedCompletedTasks = [...completedTasks, taskId];
    const updatedCanCollectTasks = canCollectTasks.filter((id) => id !== taskId);
    const updatedWaitingTasks = waitingTasks.filter(({ taskId: id }) => id !== taskId);

    setCompletedTasks(updatedCompletedTasks);
    setCanCollectTasks(updatedCanCollectTasks);
    setWaitingTasks(updatedWaitingTasks);

    const taskToComplete = tasks.find((task) => task.id === taskId);
    if (taskToComplete?.reward?.containers) {
      updateUser({ containers: user.containers + taskToComplete.reward.containers });
    } else if (taskToComplete?.reward?.rocketTicket) {
      updateUser({ rocketTicket: user.rocketTicket + taskToComplete.reward.rocketTicket });
    } else if (taskToComplete?.reward?.starBalance) {
      updateStarBalance(taskToComplete.reward.starBalance); // Folosim funcția din context
    }

    updateTasks(updatedCompletedTasks, updatedCanCollectTasks, updatedWaitingTasks, adCooldownTasks);
  };

  return (
    <div className="game-display">
      {showAdModal && <div className="dim-background"></div>}
      {showAdModalTask5 && <div className="dim-background"></div>}


      <div className={showAdModal ? 'blurred-background' : ''}>

        <h2 className='task-section-title'> Available Tasks</h2>
        <p className='task-section-subtitle'> Average review time (10-15 min)</p>

        <div className="tasks-list">
          {tasks
            .sort((a, b) => {
              const aCompleted = completedTasks.includes(a.id);
              const bCompleted = completedTasks.includes(b.id);
              return aCompleted - bCompleted;
            })
            .map((task) => (
              <div key={task.id} className={`task-item ${completedTasks.includes(task.id) ? 'completed' : ''}`}>
                <div className="task-info">
                  <div className="task-icon">
                    {task?.name?.includes('X.com') && <FaXTwitter />}
                    {task?.name?.includes('Instagram') && <FaInstagram />}
                    {task?.name?.includes('Invite') && <FaUserPlus />}
                    {task?.name?.includes('Telegram') && <FaTelegramPlane />}
                    {task?.name?.includes('Ad') && <FaDollarSign />}
                    {task?.name?.includes('Learn') && <IoReader />}
                    {task?.name?.includes('Install') && <MdInstallMobile />}
                    {completedTasks.includes(task.id) && <FaCheckCircle />}
                  </div>
                  <div className="task-details">
                    <h3>{task?.name}</h3>
                    <p>{task?.description}</p>
                  </div>
                </div>

                {task.id === 4 || task.id === 5 ? (
  isTask4Loading ? (
    <button className="task-btn loading-btn" disabled>Loading...</button>
  ) : task.id === 4 && adCountdown ? (
    <button className="task-btn cooldown-btn" disabled>
      {formatTime(adCountdown)}
    </button>
  ) : task.id === 5 && adCountdown5 ? (
    <button className="task-btn cooldown-btn" disabled>
      {formatTime(adCountdown5)}
    </button>
  ) : (
    <button
      onClick={() => handleTaskStart(task.id)}
      className="task-btn watch-ad-btn"
      disabled={isLoadingTasks}
    >
      View Ad
    </button>
  )
) : !completedTasks.includes(task.id) &&
  !canCollectTasks.includes(task.id) &&
  ![7, 8].includes(task.id) ? (
  <button
    onClick={() => handleTaskStart(task.id)}
    className="task-btn complete-task-btn"
    disabled={isLoadingTasks}
  >
    Complete
  </button>
) : !completedTasks.includes(task.id) &&
  canCollectTasks.includes(task.id) &&
  ![7, 8].includes(task.id) ? (
  <button
    onClick={() => handleTaskCompletion(task.id)}
    className="task-btn collect-btn"
    disabled={isLoadingTasks}
  >
    Collect
  </button>
) : task.id === 7 && !completedTasks.includes(task.id) && (
  <div className="invite-progress">
    {user.invitedUsers < task.target ? (
      <span>{user.invitedUsers}/{task.target} Users</span>
    ) : (
      <button
        onClick={() => handleTaskCompletion(task.id)}
        className="task-btn collect-btn"
        disabled={isLoadingTasks}
      >
        Collect
      </button>
    )}
  </div>
)}

                {task.id === 8 && !completedTasks.includes(task.id) && (
                  <div className="invite-progress">
                    {user.invitedUsers < task.target ? (
                      <span>{user.invitedUsers}/{task.target} Users</span>
                    ) : (
                      <button
                        onClick={() => handleTaskCompletion(task.id)}
                        className="task-btn collect-btn"
                        disabled={isLoadingTasks}
                      >
                        Collect
                      </button>
                    )}
                  </div>
                )}

{completedTasks.includes(task.id) && (
  <button className="task-btn completed-task-btn" disabled>
    Completed
  </button>
)}

              </div>
            ))}
        </div>
      </div>

      {showAdModal && (
  <div className="task-modal-ad">
    <div className="task-modal-ad-content">
      <h2>Congratulations!</h2>
      <p>A case has been added to your account. You will be able to complete this task again in 3 hours.</p>
      <button onClick={handleCloseModal}>Close</button>
    </div>
  </div>
)}

{showAdModalTask5 && (
  <div className="task-modal-ad">
    <div className="task-modal-ad-content">
      <h2>Congratulations!</h2>
      <p>A Rocket Ticket has been added to your account. You will be able to complete this task again within an hour.</p>
      <button onClick={handleCloseModal}>Close</button>
    </div>
  </div>
)}

    </div>
  );
};

export default TasksSection;
