import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const WalletChart = () => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  // Funcție pentru generarea ultimelor 4 luni
  const generateLastFourMonths = () => {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
    ];
    const currentMonthIndex = new Date().getMonth(); // Obține luna curentă (0-11)
    const startIndex = Math.max(0, currentMonthIndex - 3); // Ultimele 4 luni (sau mai puține la începutul anului)
    return months.slice(startIndex, currentMonthIndex + 1); // Returnează ultimele 4 luni
  };

  useEffect(() => {
    // Distrugem graficul precedent dacă există
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    const ctx = chartRef.current.getContext('2d');

    // Generăm etichetele (ultimele 4 luni)
    const labels = generateLastFourMonths();
    const data = labels.map(() => 0); // Linie dreaptă la valoarea 0

    // Creăm graficul
    chartInstanceRef.current = new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [{
          label: 'Tokens Value',
          data: data,
          backgroundColor: 'rgba(0, 123, 255, 0.1)',
          borderColor: 'rgba(0, 123, 255, 1)',
          borderWidth: 2,
          fill: true,
          tension: 0 // Linie dreaptă (fără curbură)
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            beginAtZero: true
          },
          y: {
            display: false, // Elimină scara laterală
          }
        },
        plugins: {
          legend: {
            display: true
          },
          tooltip: {
            enabled: true
          }
        }
      }
    });

    return () => {
      // Curățăm graficul la demontare
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, []);

  return (
    <div className="chart-container" style={{ height: '400px', width: '100%' }}>
      <canvas ref={chartRef}></canvas>
    </div>
  );
};

export default WalletChart;
