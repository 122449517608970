import React, { useEffect, useState } from "react";

const BackgroundMusic = ({ isMuted }) => {
  const [audio1] = useState(() => new Audio("song3.mp3"));
  const [audio2] = useState(() => new Audio("song2.mp3"));
  const [audio3] = useState(() => new Audio("song1.mp3")); // Noua melodie
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    // Funcții pentru redarea fiecărei melodii
    const playNextTrack1 = () => {
      audio2.currentTime = 0;
      audio2.play().catch(() => {});
    };

    const playNextTrack2 = () => {
      audio3.currentTime = 0;
      audio3.play().catch(() => {});
    };

    const playNextTrack3 = () => {
      audio1.currentTime = 0;
      audio1.play().catch(() => {});
    };

    if (isPlaying) {
      // Configurăm volumul
      audio1.volume = 0.1;
      audio2.volume = 0.1;
      audio3.volume = 0.1;

      // Aplicăm starea mute
      audio1.muted = isMuted;
      audio2.muted = isMuted;
      audio3.muted = isMuted;

      // Adăugăm evenimentele pentru fiecare melodie
      audio1.addEventListener("ended", playNextTrack1);
      audio2.addEventListener("ended", playNextTrack2);
      audio3.addEventListener("ended", playNextTrack3);

      // Pornim prima melodie
      audio1.play().catch(() => {});
    }

    return () => {
      // Cleanup pentru evenimente și oprirea audio
      audio1.pause();
      audio2.pause();
      audio3.pause();

      audio1.removeEventListener("ended", playNextTrack1);
      audio2.removeEventListener("ended", playNextTrack2);
      audio3.removeEventListener("ended", playNextTrack3);
    };
  }, [isPlaying, isMuted, audio1, audio2, audio3]);

  useEffect(() => {
    const handleUserInteraction = () => {
      if (!isPlaying) {
        setIsPlaying(true);
      }
    };

    const interactionEvents = ["click", "touchstart", "keydown"];
    interactionEvents.forEach((event) =>
      window.addEventListener(event, handleUserInteraction)
    );

    return () => {
      interactionEvents.forEach((event) =>
        window.removeEventListener(event, handleUserInteraction)
      );
    };
  }, [isPlaying]);

  return null;
};

export default BackgroundMusic;
