import React, { useContext, useEffect, useState } from "react";
import {
  FaArrowUp,
  FaBolt,
  FaHome,
  FaGift,
  FaTasks,
  FaBox,
  FaWallet,
  FaCoins,
  FaTicketAlt,
} from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import RefferalsSection from "./RefferalsSection";
import TasksSection from "./TasksSection";
import ContainersSection from "./ContainersSection";
import Bet from "./Bet";
import { UserContext } from "../context/UserContext";
import { AiFillHome } from "react-icons/ai";
import { HiGift } from "react-icons/hi";
import { RiCoinFill } from "react-icons/ri";
import { BsFillInboxFill } from "react-icons/bs";
import { PiWalletFill } from "react-icons/pi";
import { RiRocketFill } from "react-icons/ri";
import pb from "../pocketbase";
import { triggerHaptic, HapticType } from "./hapticFeedback";

import "./App.css";

const ranks = [
  {
    name: "Rank 1",
    requiredTaps: 3000,
    image: "./rank1.png",
    energyLimit: 500,
  },
  {
    name: "Rank 2",
    requiredTaps: 10000,
    image: "./rank2.png",
    energyLimit: 700,
  },
  {
    name: "Rank 3",
    requiredTaps: 30000,
    image: "./rank3.png",
    energyLimit: 900,
  },
  {
    name: "Rank 4",
    requiredTaps: 50000,
    image: "./rank4.png",
    energyLimit: 1100,
  },
  {
    name: "Rank 5",
    requiredTaps: 70000,
    image: "./rank5.png",
    energyLimit: 1300,
  },
  {
    name: "Rank 6",
    requiredTaps: 90000,
    image: "./rank6.png",
    energyLimit: 1500,
  },
  {
    name: "Rank 7",
    requiredTaps: 150000,
    image: "./rank7.png",
    energyLimit: 1700,
  },
  {
    name: "Rank 8",
    requiredTaps: 200000,
    image: "./rank8.png",
    energyLimit: 1800,
  },
  {
    name: "Rank 9",
    requiredTaps: 250000,
    image: "./rank9.png",
    energyLimit: 1900,
  },
  {
    name: "Rank 10",
    requiredTaps: 500000,
    image: "./rank10.png",
    energyLimit: 2000,
  },
];

const GameSection = () => {
  const {
    user,
    handleTap,
    regenerateEnergy,
    updateStarBalance,
    updateContainers,
    updateRocketTicket,
  } = useContext(UserContext);
  const [activeSection, setActiveSection] = useState("home");
  const [showRankUpModal, setShowRankUpModal] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [tapText, setTapText] = useState([]);
  const [currentGift, setCurrentGift] = useState(null); // Cadoul afișat
  const [giftPosition, setGiftPosition] = useState({ top: "50%", left: "50%" });
  const [tapCounter, setTapCounter] = useState(0);
  const [lastGiftType, setLastGiftType] = useState(null);
  const [giftTimer, setGiftTimer] = useState(null);
  const [giftTapCounter, setGiftTapCounter] = useState(0);
  const [showAdModal, setShowAdModal] = useState(false);
  const [modalReward, setModalReward] = useState(null);

  const [currentGlowColor, setCurrentGlowColor] = useState(getRandomColor());

  // Funcție pentru a genera o culoare random dintr-o paletă specifică
  function getRandomColor() {
    const colors = ["#FF0000", "#FF4500", "#1E90FF", "#32CD32", "#FFD700"];
    return colors[Math.floor(Math.random() * colors.length)];
  }

  // Schimbarea automată a culorilor (fluid, la fiecare secundă)
  useEffect(() => {
    const colorInterval = setInterval(() => {
      setCurrentGlowColor(getRandomColor());
    }, 2000);

    return () => clearInterval(colorInterval); // Cleanup pentru evitarea memory leaks
  }, []);

  // Eveniment pentru click pe buton (schimbare instantanee a culorii)
  const handleButtonClick = (e) => {
    setCurrentGlowColor(getRandomColor()); // Culoarea se schimbă instantaneu la click
  };

  useEffect(() => {
    // If a gift is present, set a timer to remove it after 5 seconds
    if (currentGift) {
      const timer = setTimeout(() => {
        setCurrentGift(null);
        setLastGiftType(null);
        setTapCounter(0);
      }, 5000);

      setGiftTimer(timer);

      // Cleanup function to clear the timer
      return () => clearTimeout(timer);
    }
  }, [currentGift]);

  // Lista de premii
  const prizes = {
    tokens: [
      { id: 1, reward: { type: "tokens", value: 500 }, chance: 90 },
      { id: 2, reward: { type: "tokens", value: 2000 }, chance: 20 },
      { id: 3, reward: { type: "tokens", value: 5000 }, chance: 5 },
    ],
    tickets: [
      { id: 4, reward: { type: "tickets", value: 1 }, chance: 20 },
      { id: 5, reward: { type: "tickets", value: 2 }, chance: 4 },
      { id: 6, reward: { type: "tickets", value: 3 }, chance: 2 },
    ],
    containers: [{ id: 7, reward: { type: "container", value: 1 }, chance: 4 }],
  };

  // Function to select a random prize based on category and chance
  const getRandomGift = () => {
    // Combinăm toate premiile din toate categoriile într-un singur array
    const allPrizes = [
      ...prizes.tokens,
      ...prizes.tickets,
      ...prizes.containers,
    ];

    // Calculăm șansa totală
    const totalChance = allPrizes.reduce((sum, prize) => sum + prize.chance, 0);

    // Generăm un număr random între 0 și șansa totală
    const randomChance = Math.random() * totalChance;

    // Determinăm premiul pe baza intervalului cumulativ
    let cumulativeChance = 0;

    for (const prize of allPrizes) {
      cumulativeChance += prize.chance;
      if (randomChance <= cumulativeChance) {
        return prize;
      }
    }

    return null; // Dacă nu se găsește nimic, returnăm null (extrem de improbabil)
  };

  const handleGiftClick = () => {
    if (currentGift) {
      // Clear the timer when gift is clicked
      if (giftTimer) {
        clearTimeout(giftTimer);
      }

      const { type, value } = currentGift.reward;
      const giftId = currentGift.id; // Preluăm ID-ul cadoului

      // Actualizare utilizator în funcție de tipul premiului
      if (type === "tokens") {
        updateStarBalance(value);
        setModalReward({
          type: "STAR tokens",
          amount: value.toLocaleString(),
        });
      } else if (type === "tickets") {
        updateRocketTicket(value);
        // Singular pentru id 4, plural pentru 5 și 6
        const ticketLabel = giftId === 4 ? "Rocket Ticket" : "Rocket Tickets";
        setModalReward({
          type: ticketLabel,
          amount: value,
        });
      } else if (type === "container") {
        updateContainers(value);
        // Singular, deoarece avem doar id 7 pentru containere
        setModalReward({
          type: "Case",
          amount: value,
        });
      }

      // Arătăm modalul
      setShowAdModal(true);

      // Resetăm starea cadoului
      setCurrentGift(null);
      setLastGiftType(null);
      setTapCounter(0);
    }
  };

  const handleCloseModal = () => {
    setShowAdModal(false);
    setModalReward(null);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      regenerateEnergy();
    }, user.energyRegenRate * 1000);

    return () => clearInterval(interval);
  }, [user.energyRegenRate]);

  useEffect(() => {
    if (user.rankIndex > user.prevRankIndex) {
      setShowSpinner(true);
      setTimeout(() => {
        setShowSpinner(false);
        setShowRankUpModal(true);
      }, 2000);
    }
  }, [user.rankIndex, user.prevRankIndex]);

  const xpProgressWidth =
    user.rankIndex < ranks.length - 1
      ? `${(user.xp / ranks[user.rankIndex].requiredTaps) * 100}%`
      : "100%";

  const xpDisplay =
    user.rankIndex < ranks.length - 1
      ? `${user.xp.toLocaleString()} / ${ranks[
          user.rankIndex
        ].requiredTaps.toLocaleString()} `
      : "Max Rank ∞";

  const tapEarnRateDisplay =
    user.tapEarnRate >= 10 ? "MAX" : user.tapEarnRate.toLocaleString();
  const energyRegenRateDisplay =
    user.energyRegenRate <= 0.1
      ? "MAX"
      : `${user.energyRegenRate.toLocaleString()}s`;

  const handleTapWithText = (e) => {
    if (user.energy > 0) {
      // Trigger light haptic feedback on successful tap
      triggerHaptic(HapticType.LIGHT_IMPACT);

      handleTap();

      // Incrementăm contorul de tap-uri pentru cadouri
      const newGiftTapCount = giftTapCounter + 1;
      setGiftTapCounter(newGiftTapCount);

      // Verificăm dacă am ajuns la intervalul necesar pentru cadou
      if (newGiftTapCount >= 400 && newGiftTapCount <= 500) {
        // Avem o șansă mai mică de apariție în intervalul specificat
        if (Math.random() < 0.3) {
          // 30% șansă
          const giftCategories = ["tokens", "tickets", "containers"];
          const randomCategory =
            giftCategories[Math.floor(Math.random() * giftCategories.length)];

          const newGift = getRandomGift(randomCategory);

          if (newGift) {
            // Trigger a more pronounced haptic feedback when a gift appears
            triggerHaptic(HapticType.MEDIUM_IMPACT);

            setCurrentGift(newGift);
            setLastGiftType(randomCategory);

            // Generăm poziție aleatoare
            const randomTop = `${Math.floor(Math.random() * 80) + 10}%`;
            const randomLeft = `${Math.floor(Math.random() * 80) + 10}%`;

            setGiftPosition({
              top: randomTop,
              left: randomLeft,
            });

            // Resetăm contorul după ce apare un cadou
            setGiftTapCounter(0);
          }
        }
      }

      // Create tap text
      const newTapText = {
        id: Date.now(),
        x: e.clientX,
        y: e.clientY,
        value: user.tapEarnRate,
      };
      setTapText((prev) => [...prev, newTapText]);
      setTimeout(() => {
        setTapText((prev) => prev.filter((text) => text.id !== newTapText.id));
      }, 1000);
    } else {
      // Feedback haptic pentru eroare când nu mai e energie
      triggerHaptic(HapticType.NOTIFICATION_ERROR);
    }
  };

  const renderSection = () => {
    switch (activeSection) {
      case "refferals":
        return <RefferalsSection />;
      case "tasks":
        return <TasksSection />;
      case "bet":
        return <Bet />;
      case "containers":
        return <ContainersSection />;
      default:
        return (
          <div className="game-display">
            {showAdModal && <div className="dim-background"></div>}

            <div className="game-xp">
              <div className="rank-image-container">
                <img
                  src={ranks[user.rankIndex].image}
                  alt="Rank"
                  className="rank-image"
                />
              </div>
              <div className="xp-bar-container">
                <div className="xp-bar">
                  <div
                    className="xp-progress"
                    style={{ width: xpProgressWidth }}
                  >
                    <p className="game-xp-value">{xpDisplay}</p>
                    <p className="game-rank-value game-xp-value">
                      Rank {user.rankIndex + 1}
                    </p>
                  </div>
                </div>
                <div className="energy-info">
                  <FaBolt className="energy-icon" />
                  <p className="energy-value">
                    {user.energy}/{ranks[user.rankIndex].energyLimit}
                  </p>
                </div>
              </div>
            </div>

            <div className="game-stats">
              <div className="stat-box">
                <p className="stat-label">
                  <FaCoins />
                  Earn Rate:{" "}
                </p>
                <p className="stat-value">{tapEarnRateDisplay} </p>
              </div>
              <div className="stat-box">
                <p className="stat-label">
                  <FaBolt />
                  Regen Time:
                </p>
                <p className="stat-value">{energyRegenRateDisplay} </p>
              </div>
              <div className="stat-box">
                <p className="stat-label">
                  <BsFillInboxFill />
                  Cases:
                </p>
                <p className="stat-value">{user.containers}</p>
              </div>
              <div className="stat-box">
                <p className="stat-label">
                  <FaUsers />
                  Invited Users:
                </p>
                <p className="stat-value">{user.invitedUsers}</p>
              </div>
              <div className="stat-box">
                <p className="stat-label">
                  <FaTicketAlt />
                  Tickets:
                </p>
                <p className="stat-value">{user.rocketTicket}</p>
              </div>
              <div className="stat-box">
                <p className="stat-label">
                  <RiCoinFill />
                  Balance:
                </p>
                <p className="stat-value">
                  {user.starBalance.toLocaleString()}
                </p>
              </div>
            </div>

            <div className="game-tap-area" onClick={handleTapWithText}>
              <div
                className="game-tap-button"
                style={{
                  "--currentGlowColor": currentGlowColor,
                }}
                onClick={handleButtonClick}
              >
                {currentGift && (
                  <div
                    className="gift-icon"
                    onClick={handleGiftClick}
                    style={{
                      position: "absolute",
                      top: giftPosition.top,
                      left: giftPosition.left,
                      transform: "translate(-50%, -50%)",
                      cursor: "pointer",
                      zIndex: 10,
                    }}
                  >
                    <FaGift size={30} color="gold" />
                  </div>
                )}
              </div>
              {tapText.map((text) => (
                <span
                  key={text.id}
                  className="tap-text"
                  style={{
                    position: "absolute",
                    top: text.y,
                    left: text.x,
                    fontWeight: "bold",
                    pointerEvents: "none",
                  }}
                >
                  {text.value} <RiCoinFill />
                </span>
              ))}
            </div>
            <div className="energy-info-mobile">
              <div className="energy-zone">
                <FaBolt className="energy-icon" />
                <p className="energy-value">
                  {user.energy}/{ranks[user.rankIndex].energyLimit}
                </p>
              </div>
              <div className="wallet-zone">
                <a
                  href="/wallet"
                  className={` ${
                    activeSection === "wallet" ? "current-page" : ""
                  }`}
                >
                  <PiWalletFill style={{ marginRight: "0px" }} />
                </a>
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <section className="game-section">
      {renderSection()}

      {(showSpinner || showRankUpModal) && (
        <div className="rankup-overlay">
          {showSpinner && (
            <div className="loader">
              <label>Advancing rank...</label>
              <div className="loading"></div>
            </div>
          )}
        </div>
      )}

      {showRankUpModal && (
        <div className="rankup-modal">
          <div className="rankup-modal-content">
            <h2>Congratulations!</h2>
            <p>
              You have ranked up to{" "}
              <strong>{ranks[user.rankIndex].name}</strong>!
            </p>
            <p>
              You have won a <strong>Case</strong>, and your new energy limit is{" "}
              <strong>{ranks[user.rankIndex].energyLimit}</strong>.
            </p>
            <img
              src={ranks[user.rankIndex].image}
              alt={`Rank ${ranks[user.rankIndex].name}`}
              className="rankup-image"
            />
            <button onClick={() => setShowRankUpModal(false)}>Close</button>
          </div>
        </div>
      )}

      <div className="game-actions-menu">
        <div
          className={`menu-item ${
            activeSection === "refferals" ? "current-page" : ""
          }`}
          onClick={() => setActiveSection("refferals")}
        >
          <HiGift style={{ marginRight: "0px" }} className="menu-icon" />
          <p className="menu-label">Reward</p>
        </div>
        <div
          className={`menu-item ${
            activeSection === "tasks" ? "current-page" : ""
          }`}
          onClick={() => setActiveSection("tasks")}
        >
          <RiCoinFill style={{ marginRight: "0px" }} className="menu-icon" />
          <p className="menu-label">Earn</p>
        </div>
        <div
          className={`menu-item ${
            activeSection === "home" ? "current-page" : ""
          }`}
          onClick={() => setActiveSection("home")}
        >
          <AiFillHome style={{ marginRight: "0px" }} className="menu-icon" />
          <p className="menu-label">Home</p>
        </div>
        <div
          className={`menu-item ${
            activeSection === "containers" ? "current-page" : ""
          }`}
          onClick={() => setActiveSection("containers")}
        >
          <BsFillInboxFill
            style={{ marginRight: "0px" }}
            className="menu-icon"
          />
          <p className="menu-label">Cases</p>
        </div>
        <div
          className={`menu-item ${
            activeSection === "bet" ? "current-page" : ""
          }`}
          onClick={() => setActiveSection("bet")}
        >
          <RiRocketFill style={{ marginRight: "0px" }} className="menu-icon" />
          <p className="menu-label">Rocket</p>
        </div>
        {showAdModal && (
          <div className="task-modal-ad">
            <div className="task-modal-ad-content">
              <h2>Congratulations!</h2>
              <p>
                You won {modalReward.amount} {modalReward.type} from the special
                gift. The rewards have been added to your account successfully!
              </p>
              <button onClick={handleCloseModal}>Close</button>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default GameSection;
