import React, { useState, useEffect, useContext, useRef } from "react";
import {
  FaHome,
  FaWallet,
  FaSearch,
  FaArrowAltCircleLeft,
  FaArrowAltCircleRight,
} from "react-icons/fa";
import { CiGlobe } from "react-icons/ci";
import { FaAngleLeft } from "react-icons/fa6";
import { IoCopyOutline } from "react-icons/io5";
import { RiMenu2Line } from "react-icons/ri";
import { TbMessages } from "react-icons/tb";
import { UserContext } from "../context/UserContext";
import { FaWindowClose } from "react-icons/fa";
import { PiHandTapFill } from "react-icons/pi";
import LogoutButton from "./LogoutButton";
import sgxImage from "./SGX.png";
import "./App.css";
import BackgroundMusic from "./BackgroundMusic";
import SoundAndVibrationControls from "./SoundAndVibrationControls";

const Home = () => {
  const { user } = useContext(UserContext);

  const [slideIndex, setSlideIndex] = useState(0);
  const [featuredIndex, setFeaturedIndex] = useState(0);
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [gameUrl, setGameUrl] = useState(null);
  const [autoSlide, setAutoSlide] = useState(true);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showPageModal, setShowPageModal] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [isMuted, setIsMuted] = useState(() => {
    const savedMutedState = localStorage.getItem("isMuted");
    return savedMutedState === "true";
  });

  useEffect(() => {
    localStorage.setItem("isMuted", isMuted);
  }, [isMuted]);

  const [isLoaderVisible, setIsLoaderVisible] = useState(() => {
    const hasLoaderBeenShown = localStorage.getItem("loaderShown");
    const lastLoginTimestamp = localStorage.getItem("lastLoginTimestamp");

    const currentTimestamp = new Date().getTime();

    const isSessionExpired =
      !lastLoginTimestamp ||
      currentTimestamp - parseInt(lastLoginTimestamp) > 30 * 60 * 1000;

    if (isSessionExpired) {
      localStorage.removeItem("loaderShown");
    }

    return !hasLoaderBeenShown;
  });

  const handleContinue = () => {
    setIsLoaderVisible(false);
    localStorage.setItem("loaderShown", "true");

    localStorage.setItem("lastLoginTimestamp", new Date().getTime().toString());
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsPageLoaded(true);
    }, 5000);

    return () => clearTimeout(timeout);
  }, []);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const closeSidebar = () => {
    setSidebarVisible(false);
  };

  const openInfoModal = () => {
    setShowInfoModal(true);
  };

  const openPageModal = () => {
    setShowPageModal(true);
  };

  const [menuVisible, setMenuVisible] = useState(false);
  const userMenuRef = useRef(null);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const handleClickOutside = (e) => {
    if (userMenuRef.current && !userMenuRef.current.contains(e.target)) {
      setMenuVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(user.id)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((err) => console.error("Failed to copy ID:", err));
  };

  const games = [
    {
      name: "Prime Tanki",
      tags: ["Action", "Multiplayer", "PvP", "Customizable"],
      bgImage: "./game4.webp",
      bgImageBlur: "./background4.webp",
      description: (
        <div>
          <p>
            <strong>
              This game is an example in our launcher, and we do not guarantee
              its listing.
            </strong>
          </p>
          <p>
            <strong>Prime Tanki</strong> is an exhilarating action game that
            immerses you in high-stakes tank battles, where strategy and skill
            determine victory. The game combines stunning graphics with advanced
            gameplay mechanics, offering players a visually captivating and
            engaging experience.
          </p>
          <p>
            In Prime Tanki, players can customize and upgrade their tanks to
            suit their personal play style. The game features a vast array of
            weapons and equipment, each contributing to your tank's performance
            on the battlefield. From enhancing armor and firepower to refining
            mobility, every upgrade is designed to give you a competitive edge.
          </p>
          <p>
            The game includes several dynamic modes, including the highly
            popular PvP (Player vs. Player) mode, where you can engage in fierce
            battles against players from around the globe. These intense matches
            test your strategic thinking and combat skills, ensuring a
            competitive and adrenaline-pumping experience.
          </p>
          <p>
            Prime Tanki also offers various other modes and challenges, each
            designed to push your tactical abilities to the limit. Whether
            you're participating in team-based skirmishes or solo combat, the
            game ensures that every battle is unique and thrilling.
          </p>
          <p>
            With its comprehensive customization options, diverse game modes,
            and engaging graphics,Prime Tanki provides a deep and rewarding
            experience for fans of action-packed tank battles and competitive
            gameplay.
          </p>
        </div>
      ),
      api: "",
    },
    {
      name: "The Witcher 3: Wild Hunt",
      tags: ["RPG", "Open World", "Story Rich", "Fantasy"],
      bgImage: "./game1.webp",
      bgImageBlur: "./background1.webp",
      description: (
        <div>
          <p>
            <strong>
              This game is an example in our launcher, and we do not guarantee
              its listing.
            </strong>
          </p>
          <p>
            <strong>The Witcher 3: Wild Hunt</strong>, developed by CD Projekt
            Red, is an open-world action RPG set in a vast and immersive fantasy
            world. Released in 2015, the game follows the story of Geralt of
            Rivia, a monster hunter in search of his adopted daughter, Ciri.
          </p>
          <p>
            The game is renowned for its deep narrative, rich world-building,
            and complex characters. Players will explore a detailed and
            expansive world filled with quests, challenging monsters, and
            various factions.
          </p>
          <p>
            The game features dynamic weather systems and a day-night cycle,
            adding to its realism and depth. The Witcher 3 is celebrated for its
            engaging storytelling, intricate side quests, and a richly detailed
            world that responds to the player's choices, offering a unique and
            memorable experience for RPG enthusiasts.
          </p>
        </div>
      ),
      api: "",
    },
    {
      name: "Remnant: From the Ashes",
      tags: ["Action", "Co-op", "Survival", "RPG"],
      bgImage: "./game3.webp",
      bgImageBlur: "./background3.webp",
      description: (
        <div>
          <p>
            <strong>
              This game is an example in our launcher, and we do not guarantee
              its listing.
            </strong>
          </p>
          <p>
            <strong>Remnant: From the Ashes</strong>, developed by Gunfire
            Games, is a third-person action RPG set in a post-apocalyptic world
            where humanity is on the brink of extinction. Released in 2019, the
            game combines intense combat with survival elements in a world
            overrun by monstrous creatures.
          </p>
          <p>
            Players assume the role of a survivor who must traverse a shattered
            world to uncover the mysteries behind the apocalyptic event and
            combat a variety of formidable enemies. The game features
            procedurally generated environments, ensuring that each playthrough
            offers a unique experience.
          </p>
          <p>
            Combat is fast-paced and strategic, emphasizing teamwork and
            skillful use of weapons and abilities. The game supports both solo
            and multiplayer modes, allowing players to team up with others to
            tackle challenging encounters and bosses.
          </p>
          <p>
            It is praised for its challenging gameplay, rich world-building, and
            the depth of its combat mechanics, offering a rewarding experience
            for fans of cooperative and survival-oriented games.
          </p>
        </div>
      ),
      api: "",
    },
    {
      name: "Cyberpunk 2077",
      tags: ["RPG", "Sci-Fi", "Open World", "Action"],
      bgImage: "./game2.webp",
      bgImageBlur: "./background2.webp",
      description: (
        <div>
          <p>
            <strong>
              This game is an example in our launcher, and we do not guarantee
              its listing.
            </strong>
          </p>
          <p>
            <strong>Cyberpunk 2077</strong>, developed by CD Projekt Red, is a
            futuristic open-world RPG set in the neon-drenched streets of Night
            City. Released in 2020, the game follows V, a mercenary with
            customizable skills and cybernetic enhancements, as they navigate a
            dystopian world filled with corporate intrigue and cybernetic
            enhancements.
          </p>
          <p>
            The game features a rich narrative, branching storylines, and a
            dynamic open world where players can engage in a variety of
            activities, from combat and hacking to exploring the city and
            interacting with diverse characters.
          </p>
          <p>
            Cyberpunk 2077 is known for its high level of detail, expansive
            world-building, and the depth of its character customization
            options, offering players a unique and immersive experience in a
            futuristic setting.
          </p>
        </div>
      ),
      api: "",
    },
  ];

  const handleSlide = (direction) => {
    setSlideIndex((prevIndex) => {
      const newIndex =
        direction === "left"
          ? prevIndex === 0
            ? games.length - 1
            : prevIndex - 1
          : (prevIndex + 1) % games.length;

      setFeaturedIndex(newIndex);
      return newIndex;
    });
  };

  const handleCloseGame = () => {
    setGameUrl(null);
    document.body.style.overflow = "auto";
  };

  const handleGameClick = (index) => {
    setFeaturedIndex(index);
    setSlideIndex(index);
    setAutoSlide(false);
  };

  useEffect(() => {
    let autoSlideInterval;
    if (autoSlide) {
      autoSlideInterval = setInterval(() => {
        setSlideIndex((prevIndex) => {
          const newIndex = prevIndex === games.length - 1 ? 0 : prevIndex + 1;
          setFeaturedIndex(newIndex);
          return newIndex;
        });
      }, 30000);
    }
    return () => clearInterval(autoSlideInterval);
  }, [autoSlide]);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        handleCloseGame();
      }
    };

    document.addEventListener("keydown", handleEsc);
    return () => document.removeEventListener("keydown", handleEsc);
  }, []);

  const selectedGame = games[featuredIndex];

  return (
    <div className={`home ${gameUrl ? "hidden" : ""}`}>
      <BackgroundMusic isMuted={isMuted} />

      {isLoaderVisible && (
        <div className={`loader-container ${isPageLoaded ? "loaded" : ""}`}>
          <div
            className="loader-image"
            style={{
              backgroundImage: `url(${
                window.innerWidth <= 1080
                  ? "loader-mini.webp" // Imagine pentru ecrane mici
                  : "loader.webp" // Imagine pentru ecrane mari
              })`,
            }}
          >
            {/* Titlu mare deasupra loaderului */}
            <h1 className="loader-title">
              HOLIDAYS FEEL DIFFERENT WITH STARLESSX
            </h1>

            {/* Spinner pentru încărcare */}
            {!isPageLoaded && (
              <div className="spinner2">
                <div className="spinner1"></div>
              </div>
            )}

            {/* Buton "Continue" */}
            {isPageLoaded && (
              <button className="loader-button" onClick={handleContinue}>
                Continue
              </button>
            )}

            {/* Text jos în partea de jos */}
            <p className="loader-footer">Made with ❤️ by the StarlessX team</p>
          </div>
        </div>
      )}

      {gameUrl && (
        <iframe
          src={gameUrl}
          title="Game"
          className="game-frame"
          style={{ width: "100%", height: "100vh" }}
          onLoad={() => (document.body.style.overflow = "hidden")}
        />
      )}
      <div
        className="background-transition"
        style={{
          backgroundImage: `url(${
            selectedGame?.bgImageBlur || "./defaultBackground.webp"
          })`, // Fundal fallback
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <ul class="lightrope">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <div class="snowflakes" aria-hidden="true">
        <div class="snowflake">❅</div>
        <div class="snowflake">❅</div>
        <div class="snowflake">❆</div>
        <div class="snowflake">❄</div>
        <div class="snowflake">❅</div>
        <div class="snowflake">❆</div>
        <div class="snowflake">❄</div>
        <div class="snowflake">❅</div>
        <div class="snowflake">❆</div>
        <div class="snowflake">❄</div>
      </div>

      <nav className={`sidebar ${sidebarVisible ? "show" : ""}`}>
        <div className="logo">
          <img src="./logo.png" alt="StarlessX-Games-Logo" />
        </div>
        <div className="close-area" onClick={closeSidebar}>
          <FaAngleLeft className="close-icon" />
        </div>

        <ul className="menu">
          <hr className="menu-line" />
          <a href="/home" className="current-section">
            <FaHome style={{ fontSize: "20px" }} /> Home
          </a>
          <a href="/tap-to-earn" className="section-name">
            <PiHandTapFill style={{ fontSize: "20px" }} /> Tap To Earn
          </a>

          <hr className="menu-line" />

          <a href="/wallet" className="section-name">
            <FaWallet style={{ fontSize: "20px" }} /> Wallet
          </a>
          <hr className="menu-line" />

          <a
            href="https://starlessx.com/contact"
            target="_blank"
            className="support-button-side"
          >
            <TbMessages />
            Get Support
          </a>
          <a
            href="https://starlessx.com"
            target="_blank"
            className="support-button-side"
          >
            <CiGlobe />
            Explore StarlessX
          </a>
          <hr className="menu-line menu-line2" />
          <SoundAndVibrationControls
            isMuted={isMuted}
            setIsMuted={setIsMuted}
          />
        </ul>
      </nav>

      <main className="content">
        {showInfoModal && <div className="dim-background"></div>}{" "}
        {/* Stratul de întunecare */}
        {showPageModal && <div className="dim-background"></div>}{" "}
        {/* Stratul de întunecare */}
        <div className="header">
          <div className="sidebar-toggler" onClick={toggleSidebar}>
            <RiMenu2Line className="toggler-button" />
          </div>
          <div className="nav-logo">
            <img src="./logo.png" alt="StarlessX-Games-Logo" />
          </div>
          <div className="search-container">
            {showSearchIcon && <FaSearch className="search-icon" />}
            <input
              type="text"
              placeholder="Type to search"
              className="search-input"
            />
          </div>
          <div className="icons">
            <li className="total-SGX-balance" style={{ listStyle: "none" }}>
              {user.sgxBalance ? user.sgxBalance.toFixed(2) : "0.00"} SGX
            </li>
            <a
              href="https://starlessx.com/contact"
              target="_blank"
              className="support-button"
            >
              <TbMessages />
              Get Support
            </a>
            <img
              src={sgxImage}
              onClick={toggleMenu}
              alt="SGX"
              className="user-image user-menu-trigger-icon"
            />
            {menuVisible && (
              <div ref={userMenuRef} className="user-dropdown-menu">
                <div className="user">
                  <img src={sgxImage} alt="SGX" className="user-image" />
                  <div className="user-menu">
                    <div className="user-info">
                      <div className="user-name-tag">
                        <span className="user-name">{user.username}</span>
                      </div>
                      <div className="user-mail">
                        <span
                          className="address"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          ID: {user.id}
                          <button
                            onClick={handleCopy}
                            className="copy-button"
                            style={{
                              background: "none",
                              border: "none",
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {copied ? "Copied!" : <IoCopyOutline />}
                          </button>
                        </span>{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="menu-line" />
                <ul className="down-menu">
                  <LogoutButton className="logout-button" />
                </ul>
              </div>
            )}
          </div>
        </div>
        <section
          className="featured-game"
          style={{ backgroundImage: `url(${selectedGame.bgImage})` }}
        >
          <div className="featured-game-info">
            <h2>{selectedGame.name}</h2>
            <p className="tags">
              {selectedGame.tags.map((tag, index) => (
                <span key={index}>{tag}</span>
              ))}
            </p>
          </div>
          <button
            onClick={() =>
              window.open(
                "https://starlessx.com/about-games",
                "_blank",
                "noopener,noreferrer"
              )
            }
          >
            View More
          </button>
        </section>
        <section className="most-popular">
          <div className="most-popular-header">
            <h2>
              The Most Popular{" "}
              <span role="img" aria-label="fire">
                🔥
              </span>
            </h2>
            <div className="slider-buttons">
              <button onClick={() => handleSlide("left")}>
                <FaArrowAltCircleLeft style={{ marginRight: "0px" }} />
              </button>
              <button onClick={() => handleSlide("right")}>
                <FaArrowAltCircleRight style={{ marginRight: "0px" }} />
              </button>
            </div>
          </div>
          <div className="games-container">
            <div
              className="games"
              style={{ transform: `translateX(${-slideIndex * (75 / 3)}%)` }}
            >
              {games.map((game, index) => (
                <div
                  key={index}
                  className="game"
                  style={{ backgroundImage: `url(${game.bgImage})` }}
                  onClick={() => handleGameClick(index)}
                >
                  <div className="popular-game-info">
                    <h3 className="game-name">{game.name}</h3>
                    <p className="tags">
                      {game.tags.map((tag, index) => (
                        <span key={index}>{tag}</span>
                      ))}
                    </p>
                  </div>
                  <p className="view" onClick={() => handleGameClick(index)}>
                    View
                  </p>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section className="game-description">
          <h2 className="desc-title">About This Game</h2>
          <p>{selectedGame.description}</p>
        </section>
        {showInfoModal && (
          <div className="info-modal">
            <div className="info-modal-content">
              <h2>Feature in Progress</h2>
              <p>
                This page is still under development. As StarlessX is in its
                early stages, many features and components are still being
                worked on. Thank you for your understanding and patience.
              </p>
              <button onClick={() => setShowInfoModal(false)}>Close</button>
            </div>
          </div>
        )}
        {showPageModal && (
          <div className="page-modal">
            <div className="page-modal-content">
              <h2>Page Under Development</h2>
              <p>
                This page is still under development. As StarlessX is in its
                early stages, many features and components are still being
                worked on. Thank you for your understanding and patience.
              </p>
              <button onClick={() => setShowPageModal(false)}>Close</button>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default Home;
