import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import pb from '../pocketbase';
import './App.css';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const navigate = useNavigate();

  const { loginUser, updateInvitedUsers, updateContainers } = useContext(UserContext);

  // Funcția pentru aplicarea recompenselor în așteptare la logare
const applyPendingUpdates = async (userId) => {
  try {
    const waitingRecord = await pb.collection('waitings').getFirstListItem(`userId="${userId}"`);

    if (waitingRecord && (waitingRecord.waitingContainersUpdate > 0 || waitingRecord.waitingInvitedUsersUpdate > 0)) {
      const userRecord = await pb.collection('users').getOne(userId);

      const updatedUserData = {
        containers: userRecord.containers + (waitingRecord.waitingContainersUpdate || 0),
        invitedUsers: userRecord.invitedUsers + (waitingRecord.waitingInvitedUsersUpdate || 0),
      };
      
      await pb.collection('users').update(userId, updatedUserData);

      if (waitingRecord.waitingContainersUpdate > 0) {
        updateContainers(waitingRecord.waitingContainersUpdate); // Apelăm cu numărul de containere
      }

      if (waitingRecord.waitingInvitedUsersUpdate > 0) {
        updateInvitedUsers(waitingRecord.waitingInvitedUsersUpdate);
      }

      await pb.collection('waitings').update(waitingRecord.id, {
        waitingContainersUpdate: 0,
        waitingInvitedUsersUpdate: 0,
      });

      console.log("Pending updates applied successfully for user:", userId);
    }
  } catch (error) {
    console.error("Error applying pending updates:", error);
  }
};



  // Funcția de login care include apelul la applyPendingUpdates
  const handleLogin = async () => {
    if (!username || !password) {
      setMessage('Please enter both username and password.');
      return;
    }

    setIsLoggingIn(true);

    try {
      const authData = await pb.collection('users').authWithPassword(username, password);
      console.log("User logged in:", authData);
      setMessage('Login successful! Redirecting...');

      // Aplicați actualizările în așteptare pentru utilizatorul autentificat
      await applyPendingUpdates(authData.record.id);

      // Apelăm funcția loginUser din context pentru a salva autentificarea în localStorage
      loginUser();

      // Navigăm către pagina de start
      setTimeout(() => {
        navigate('/home');
      }, 1500);
    } catch (error) {
      console.error('Error during login:', error);
    
      // Logăm întregul obiect de eroare pentru debugging
      console.log('Full error object:', JSON.stringify(error, null, 2));
      // Verificăm dacă este o eroare de autentificare sau o eroare critică
      if (error.response && error.response.status === 400) {
        // Autentificare eșuată (email sau parolă greșită)
        setMessage('Invalid email or password. Please try again.');
      } else if (error.status === 0 || error.response === undefined) {
        // Eroare critică de rețea sau server căzut
        setMessage('There are issues on our side. Please try again later.');
      } else if (error.status === 403 || error.response === undefined) {
        // Eroare critică de rețea sau server căzut
        setMessage(`${error.message}`);
            }  else {
        // Mesaj fallback generic dacă nu există detalii de eroare clare
        setMessage('Wrong username or password');
      }
      setIsLoggingIn(false); // Resetăm starea de logare
    }
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <div className="login-image-container">
          <img 
            src="login-img.webp" 
            alt="Starless Games" 
            className="login-image"
          />
        </div>
        <div className="login-body">
          <div className="login-header">
            <i className="login-icon fas fa-cubes fa-3x"></i>
            <span className="login-logo">Welcome back</span>
          </div>
          <h5 className="login-title">Login to your account</h5>
          <input 
            className="login-input"
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input 
            className="login-input"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button className="login-button" onClick={handleLogin} disabled={isLoggingIn}>
            {isLoggingIn ? 'Logging in...' : 'Login'}
          </button>
          <p className="login-message">{message}</p>
          <p className="login-register">
            Don't have an account? <a className="login-register-link" href="/register">Register here</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Login;
