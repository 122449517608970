// src/utils/hapticFeedback.js

// Enum pentru tipurile de feedback haptic
export const HapticType = {
  LIGHT_IMPACT: 'light',
  MEDIUM_IMPACT: 'medium',
  HEAVY_IMPACT: 'heavy',
  NOTIFICATION_SUCCESS: 'success',
  NOTIFICATION_WARNING: 'warning', 
  NOTIFICATION_ERROR: 'error'
};

// Funcție pentru a detecta dispozitivele mobile
const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

// Funcție principală pentru feedback haptic
export const triggerHaptic = (type = HapticType.LIGHT_IMPACT) => {
  // Verifică dacă dispozitivul suportă vibrații
  if (isMobileDevice() && 'vibrate' in navigator) {
    switch (type) {
      case HapticType.LIGHT_IMPACT:
        navigator.vibrate(20);
        break;
      case HapticType.MEDIUM_IMPACT:
        navigator.vibrate(40);
        break;
      case HapticType.HEAVY_IMPACT:
        navigator.vibrate(60);
        break;
      case HapticType.NOTIFICATION_SUCCESS:
        // Un model simpatic de vibrație pentru succes
        navigator.vibrate([30, 50, 30]);
        break;
      case HapticType.NOTIFICATION_WARNING:
        navigator.vibrate([50, 30, 50]);
        break;
      case HapticType.NOTIFICATION_ERROR:
        // Un model de vibrație care indică o eroare
        navigator.vibrate([100, 50, 100]);
        break;
      default:
        navigator.vibrate(30);
    }
  }
};

// Funcție pentru a declanșa haptic conditional
export const conditionalHaptic = (condition, type = HapticType.LIGHT_IMPACT) => {
  if (condition) {
    triggerHaptic(type);
  }
};
