// src/pages/ContainersSection.jsx

import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import { FaInfoCircle } from "react-icons/fa";
import "./App.css";

const ContainersSection = () => {
  const [rolledItem, setRolledItem] = useState(null);
  const [inventory, setInventory] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isRolling, setIsRolling] = useState(false);
  const {
    user,
    updateStarBalance,
    updateRocketTicket,
    updateEnergyRegenRate,
    updateTapEarnRate,
    decrementContainerCount,
    updateSGXBalance,
  } = useContext(UserContext);

  const items = [
    {
      name: `You've won the Mythic card, which contains 3 game tickets for the Bet game. You now have a total of ${
        user.rocketTicket + 3
      } tickets in your account.`,
      rarity: "legendary",
      chance: 3,
      img: `./5tickets.webp`,
    },
    {
      name: `You've won the Mythic card, which gives you +2 tokens to the earning rate for the STAR token in the 'Home' section. Your new earning rate is now ${Math.min(
        user.tapEarnRate + 2,
        10
      ).toFixed(1)} tokens per tap.`,
      rarity: "legendary",
      chance: 3,
      img: `./2earn.webp`,
    },
    {
      name: `You've won the Mythic card, which reduces the waiting time for energy regeneration by 0.2 seconds. You will now receive 2 energy points every ${Math.max(
        user.energyRegenRate - 0.2,
        0.1
      ).toFixed(2)} seconds.`,
      rarity: "legendary",
      chance: 3,
      img: `./0.3regen.webp`,
    },
    {
      name: `You've won the Mythic card, which adds 100,000 STAR tokens to your account balance. You now have ${(
        user.starBalance + 100000
      ).toLocaleString()} STAR tokens in your balance.`,
      rarity: "legendary",
      chance: 3,
      img: `./100000star.webp`,
    },
    {
      name: `You've won the Epic card, which contains 2 game tickets for the Bet game. You now have a total of ${
        user.rocketTicket + 2
      } tickets in your account.`,
      rarity: "rare",
      chance: 15,
      img: `./3tickets.webp`,
    },
    {
      name: `You've won the Epic card, which gives you +1 token to the earning rate for the STAR token in the 'Home' section. Your new earning rate is now ${Math.min(
        user.tapEarnRate + 1,
        10
      ).toFixed(1)} tokens per tap.`,
      rarity: "rare",
      chance: 15,
      img: `./1earn.webp`,
    },
    {
      name: `You've won the Epic card, which reduces the waiting time for energy regeneration by 0.1 seconds. You will now receive 2 energy points every ${Math.max(
        user.energyRegenRate - 0.1,
        0.1
      ).toFixed(2)} seconds.`,
      rarity: "rare",
      chance: 15,
      img: `./0.2regen.webp`,
    },
    {
      name: `You've won the Epic card, which adds 35,000 STAR tokens to your account balance. You now have ${(
        user.starBalance + 35000
      ).toLocaleString()} STAR tokens in your balance.`,
      rarity: "rare",
      chance: 15,
      img: `./35000star.webp`,
    },
    {
      name: `You've won the Rare card, which contains 1 game ticket for the Bet game. You now have a total of ${
        user.rocketTicket + 1
      } tickets in your account.`,
      rarity: "common",
      chance: 82,
      img: `./1ticket.webp`,
    },
    {
      name: `You've won the Rare card, which gives you +0.5 tokens to the earning rate for the STAR token in the 'Home' section. Your new earning rate is now ${Math.min(
        user.tapEarnRate + 0.5,
        10
      ).toFixed(1)} tokens per tap.`,
      rarity: "common",
      chance: 82,
      img: `./0.5earn.webp`,
    },
    {
      name: `You've won the Rare card, which reduces the waiting time for energy regeneration by 0.05 seconds. You will now receive 2 energy points every ${Math.max(
        user.energyRegenRate - 0.05,
        0.1
      ).toFixed(2)} seconds.`,
      rarity: "common",
      chance: 82,
      img: `./0.1regen.webp`,
    },
    {
      name: `You've won the Rare card, which adds 10,000 STAR tokens to your account balance. You now have ${(
        user.starBalance + 10000
      ).toLocaleString()} STAR tokens in your balance.`,
      rarity: "common",
      chance: 82,
      img: `./10000star.webp`,
    },
  ];

  const shuffleItemsByChance = () => {
    const shuffled = [];
    items.forEach((item) => {
      for (let i = 0; i < item.chance; i++) {
        shuffled.push(item);
      }
    });
    return shuffled.sort(() => Math.random() - 0.5);
  };

  let timeoutId;

  const generate = () => {
    if (user.containers <= 0 || isRolling) return;

    setIsRolling(true);
    decrementContainerCount();

    const container = document.querySelector(".raffle-roller-container");
    if (!container) return;

    container.style.marginLeft = "0px";
    container.innerHTML = "";

    const shuffledItems = shuffleItemsByChance();
    const numberOfItems = 100;
    const itemWidth = 240;

    for (let i = 0; i < numberOfItems; i++) {
      const item = shuffledItems[i % shuffledItems.length];
      const element = `<div id="CardNumber${i}" class="item" style="background-image:url(${item.img}); width: ${itemWidth}px; height: 135px;"></div>`;
      container.innerHTML += element;
    }

    container.style.width = `${numberOfItems * itemWidth}px`;

    timeoutId = setTimeout(() => {
      goRoll(shuffledItems, itemWidth);
    }, 500);
  };

  const goRoll = async (shuffledItems, itemWidth) => {
    const container = document.querySelector(".raffle-roller-container");
    if (!container) return;

    const visibleItems = Math.floor(
      container.parentElement.clientWidth / itemWidth
    );
    const centerIndex = Math.floor(visibleItems / 2);
    const totalShift =
      Math.floor(Math.random() * (100 - visibleItems)) * itemWidth;

    const randomSpeed = 4 + Math.random() * 4;
    container.style.transition = `margin-left ${randomSpeed}s cubic-bezier(.08,.6,0,1)`;
    container.style.marginLeft = `-${totalShift}px`;

    timeoutId = setTimeout(async () => {
      const finalPosition = parseFloat(
        window.getComputedStyle(container).marginLeft
      );
      const correctedFinalPosition = -finalPosition + centerIndex * itemWidth;
      const winningIndex =
        Math.round(correctedFinalPosition / itemWidth) % shuffledItems.length;

      if (winningIndex < 0 || winningIndex >= shuffledItems.length) {
        console.error("Invalid winning index:", winningIndex);
        setIsRolling(false);
        return;
      }

      const winningItem = shuffledItems[winningIndex];
      if (!winningItem) {
        console.error("Winning item is undefined at index:", winningIndex);
        setIsRolling(false);
        return;
      }

      setInventory((prevInventory) => [
        ...prevInventory,
        { skin: winningItem.name, img: winningItem.img },
      ]);

      if (
        winningItem.name ===
        `You've won the Mythic card, which contains 3 game tickets for the Bet game. You now have a total of ${
          user.rocketTicket + 3
        } tickets in your account.`
      ) {
        await updateRocketTicket(3);
      } else if (
        winningItem.name ===
        `You've won the Mythic card, which gives you +2 tokens to the earning rate for the STAR token in the 'Home' section. Your new earning rate is now ${Math.min(
          user.tapEarnRate + 2,
          10
        ).toFixed(1)} tokens per tap.`
      ) {
        await updateTapEarnRate(2);
      } else if (
        winningItem.name ===
        `You've won the Mythic card, which reduces the waiting time for energy regeneration by 0.2 seconds. You will now receive 2 energy points every ${Math.max(
          user.energyRegenRate - 0.2,
          0.1
        ).toFixed(2)} seconds.`
      ) {
        await updateEnergyRegenRate(0.2);
      } else if (
        winningItem.name ===
        `You've won the Mythic card, which adds 100,000 STAR tokens to your account balance. You now have ${(
          user.starBalance + 100000
        ).toLocaleString()} STAR tokens in your balance.`
      ) {
        await updateStarBalance(100000);
      } else if (
        winningItem.name ===
        `You've won the Epic card, which contains 2 game tickets for the Bet game. You now have a total of ${
          user.rocketTicket + 2
        } tickets in your account.`
      ) {
        await updateRocketTicket(2);
      } else if (
        winningItem.name ===
        `You've won the Epic card, which gives you +1 token to the earning rate for the STAR token in the 'Home' section. Your new earning rate is now ${Math.min(
          user.tapEarnRate + 1,
          10
        ).toFixed(1)} tokens per tap.`
      ) {
        await updateTapEarnRate(1);
      } else if (
        winningItem.name ===
        `You've won the Epic card, which reduces the waiting time for energy regeneration by 0.1 seconds. You will now receive 2 energy points every ${Math.max(
          user.energyRegenRate - 0.1,
          0.1
        ).toFixed(2)} seconds.`
      ) {
        await updateEnergyRegenRate(0.1);
      } else if (
        winningItem.name ===
        `You've won the Epic card, which adds 35,000 STAR tokens to your account balance. You now have ${(
          user.starBalance + 35000
        ).toLocaleString()} STAR tokens in your balance.`
      ) {
        await updateStarBalance(35000);
      } else if (
        winningItem.name ===
        `You've won the Rare card, which contains 1 game ticket for the Bet game. You now have a total of ${
          user.rocketTicket + 1
        } tickets in your account.`
      ) {
        await updateRocketTicket(1);
      } else if (
        winningItem.name ===
        `You've won the Rare card, which gives you +0.5 tokens to the earning rate for the STAR token in the 'Home' section. Your new earning rate is now ${Math.min(
          user.tapEarnRate + 0.5,
          10
        ).toFixed(1)} tokens per tap.`
      ) {
        await updateTapEarnRate(0.5);
      } else if (
        winningItem.name ===
        `You've won the Rare card, which reduces the waiting time for energy regeneration by 0.05 seconds. You will now receive 2 energy points every ${Math.max(
          user.energyRegenRate - 0.05,
          0.1
        ).toFixed(2)} seconds.`
      ) {
        await updateEnergyRegenRate(0.05);
      } else if (
        winningItem.name ===
        `You've won the Rare card, which adds 10,000 STAR tokens to your account balance. You now have ${(
          user.starBalance + 10000
        ).toLocaleString()} STAR tokens in your balance.`
      ) {
        await updateStarBalance(10000);
      }

      setRolledItem(winningItem.name);
      setShowModal(true);
      setIsRolling(false);
    }, randomSpeed * 1000);
  };

  return (
    <div className="game-display">
      <h2>Cases</h2>

      <div className="case-space">
        <div className="raffle-roller">
          <div className="raffle-roller-holder">
            <div
              className="raffle-roller-container"
              style={{ marginLeft: "0px" }}
            ></div>
            <div className="vertical-line"></div>
          </div>
        </div>

        <div className="button-container">
          <button
            onClick={generate}
            disabled={user.containers <= 0 || isRolling}
            className={
              user.containers > 0 && !isRolling
                ? "active-button"
                : "disabled-button"
            }
          >
            {`Open Case (${user.containers})`}
          </button>
        </div>
      </div>

      <div className="contains-space">
        <p>
          <FaInfoCircle style={{ verticalAlign: "middle" }} />
          This case includes:
        </p>
      </div>
      <div className="rewards">
        {items.map((item, index) => (
          <div
            key={index}
            className="item"
            style={{
              backgroundImage: `url(${item.img})`,
              width: "213.33px",
              height: "120px",
            }}
          ></div>
        ))}
      </div>

      {showModal && (
        <>
          <div className="modal-overlay"></div>
          <div className="case-modal">
            <div className="case-modal-content">
              <h2>Congratulations!</h2>
              <p>{rolledItem}</p>
              <button onClick={() => setShowModal(false)}>Close</button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ContainersSection;
